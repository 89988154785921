import React, { useState, useEffect } from 'react';
import { BoxSelection, BoxSize } from 'wix-ui-tpa/BoxSelection';
import {
  TimeSelectionViewModel,
  TimeSlot,
} from '../../../ViewModel/timeSelectionViewModel/timeSelectionViewModel';
import { classes, st } from './TimeSelection.st.css';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { Text } from 'wix-ui-tpa/Text';
import { ReactComponent as CaretDown } from 'wix-ui-tpa/dist/src/assets/icons/CaretDown.svg';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export type TimeSelectionProps = {
  viewModel: TimeSelectionViewModel;
};

export const TIME_DELAY_BEFORE_FOCUS = 0;

const TimeSelection: React.FC<TimeSelectionProps> = ({ viewModel }) => {
  const {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed,
    maxNumberOfTimeSlotsToDisplay,
    showAllButtonText,
    highlightedSlotDetails,
  } = viewModel;
  const boxSelectionRef = React.useRef(null as any);
  const { onTimeSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const [numberOfTimeSlotsToDisplay, setNumberOfTimeSlotsToDisplay] =
    useState(0);
  const shouldDisplayShowAllButton =
    timeSlots.length > numberOfTimeSlotsToDisplay;
  useEffect(() => {
    const defaultNumberOfSlotsToDisplay = shouldLimitNumberOfTimeSlotsDisplayed
      ? maxNumberOfTimeSlotsToDisplay
      : timeSlots.length;
    setNumberOfTimeSlotsToDisplay(defaultNumberOfSlotsToDisplay);
  }, [maxNumberOfTimeSlotsToDisplay, shouldLimitNumberOfTimeSlotsDisplayed]);
  useEffect(() => {
    if (highlightedSlotDetails.shouldFocus) {
      setTimeout(
        () =>
          boxSelectionRef.current?.focus?.(highlightedSlotDetails.slotIndex),
        TIME_DELAY_BEFORE_FOCUS,
      );
    }
  }, [highlightedSlotDetails]);

  return (
    <div className={st(classes.root, { isMobile })}>
      <BoxSelection
        name="time-selection"
        data-hook="time-selection"
        className={classes.boxSelection}
        size={BoxSize.small}
        onChange={({ id }) => onTimeSelected(id)}
        ref={boxSelectionRef}
      >
        {timeSlots
          .slice(0, numberOfTimeSlotsToDisplay)
          .map((timeSlot: TimeSlot, index: number) => {
            const {
              selected,
              allSlotsAreFull,
              tooLateToBookAllSlots,
              tooEarlyToBookAllSlots,
              withWaitingList,
            } = timeSlot.status;
            const isTimeSlotFull = allSlotsAreFull && !withWaitingList;
            const isDisabled =
              isTimeSlotFull || tooLateToBookAllSlots || tooEarlyToBookAllSlots;
            return (
              <BoxSelection.Option
                key={index}
                id={timeSlot.rfcStartTime}
                className={classes.boxSelectionOption}
                aria-label={timeSlot.ariaLabel}
                checked={selected}
                disabled={isDisabled}
                unavailable={isTimeSlotFull || tooLateToBookAllSlots}
              >
                <Text
                  data-hook={`time-slot-text-${index}`}
                  className={st(classes.boxSelectionText, {
                    isDisabled,
                  })}
                >
                  <div className={classes.startTime}>
                    {timeSlot.formattedStartTime}
                  </div>
                  {withWaitingList ? (
                    <div className={classes.waitingList}>
                      {viewModel.waitlistText}
                    </div>
                  ) : null}
                </Text>
              </BoxSelection.Option>
            );
          })}
      </BoxSelection>
      {shouldDisplayShowAllButton ? (
        <TextButton
          data-hook="show-all-button"
          priority={TEXT_BUTTON_PRIORITY.secondary}
          suffixIcon={<CaretDown className={classes.showAllButtonSuffix} />}
          onClick={() => setNumberOfTimeSlotsToDisplay(timeSlots.length)}
          className={classes.showAllButton}
        >
          {showAllButtonText}
        </TextButton>
      ) : null}
    </div>
  );
};

export default TimeSelection;
